import { template as template_05f1cfb477a94459abc74fbbb4c9f9a7 } from "@ember/template-compiler";
import CampaignResultAverage from '../cards/result-average';
import CampaignSharedCount from '../cards/shared-count';
import CampaignStageAverage from '../cards/stage-average';
export default template_05f1cfb477a94459abc74fbbb4c9f9a7(`
  <div class="assessment-cards">
    {{#if @hasStages}}
      <CampaignStageAverage
        @totalStage={{@totalStage}}
        @reachedStage={{@reachedStage}}
        @stages={{@stages}}
        class="assessment-cards__average-card"
      />
    {{else}}
      <CampaignResultAverage @value={{@averageResult}} class="assessment-cards__average-card" />
    {{/if}}

    <CampaignSharedCount @value={{@sharedParticipationsCount}} @shouldDisplayAssessmentLabels={{true}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
