import { template as template_6384cd6c0c8f4f38bcd7f75777681b97 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_6384cd6c0c8f4f38bcd7f75777681b97(`
  <p class="screen-reader-only">{{t "charts.participants-by-mastery-percentage.loader"}}</p>
  <div class="participants-by-mastery-percentage--loader placeholder-box" aria-hidden="true"></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
