import { template as template_6b79b2f1bbc44ad386e562ff20e96a94 } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class List extends Component {
    @service
    currentUser;
    get isNotManagingStudents() {
        return !this.currentUser.isSCOManagingStudents && !this.currentUser.isSUPManagingStudents;
    }
    static{
        template_6b79b2f1bbc44ad386e562ff20e96a94(`
    <PixBlock class="no-participant-panel">
      <img src="{{this.rootURL}}/images/empty-state-participants.svg" alt="" role="none" />
      <p class="content-text">
        {{t "pages.organization-participants.empty-state.message"}}
        {{#if this.isNotManagingStudents}}
          <br />
          {{t "pages.organization-participants.empty-state.call-to-action"}}
          <LinkTo @route="authenticated.campaigns.list.my-campaigns" class="link link--underlined">
            {{t "pages.organization-participants.empty-state.action"}}
          </LinkTo>
        {{/if}}
      </p>
    </PixBlock>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
