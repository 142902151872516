import { template as template_6c62cd886f1643f89ccafbfd55a787ec } from "@ember/template-compiler";
export default template_6c62cd886f1643f89ccafbfd55a787ec(`
  <section class="import-card">
    <h2 class="import-card__title">{{@cardTitle}}</h2>
    <div class="import-card__details">
      {{yield to="cardDetails"}}
    </div>

    <div class="import-card__footer">
      {{yield to="cardFooter"}}
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
