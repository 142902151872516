import { template as template_9dc22238a8f14a0fa63ca60db92f37b5 } from "@ember/template-compiler";
export default template_9dc22238a8f14a0fa63ca60db92f37b5(`
  <dl class="information-wrapper" ...attributes>
    {{yield}}
  </dl>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
