import { template as template_25002c9f354e48fb9dd742f39c5fe7dd } from "@ember/template-compiler";
import PixTabs from '@1024pix/pix-ui/components/pix-tabs';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_25002c9f354e48fb9dd742f39c5fe7dd(`
  <PixTabs @variant="orga" class="mission-navigation__tabs" @ariaLabel={{t "pages.missions.mission.tabs.aria-label"}}>
    <LinkTo @route="authenticated.missions.mission.activities">
      {{t "pages.missions.mission.tabs.activities"}}
    </LinkTo>
    <LinkTo @route="authenticated.missions.mission.results">
      {{t "pages.missions.mission.tabs.results"}}
    </LinkTo>
  </PixTabs>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
