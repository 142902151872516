import { template as template_19d2afc6e3e54b48a83f1e5dd7e4a567 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_19d2afc6e3e54b48a83f1e5dd7e4a567(`
  <div class="app-loader">
    {{! template-lint-disable no-redundant-role }}
    <img src="/images/interwind.gif" alt="" role="presentation" />
    <p class="app-loader__text">
      {{t "common.loading"}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
