import { template as template_bd1a2a4865d742169b5d7aaa9b9ebea9 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_bd1a2a4865d742169b5d7aaa9b9ebea9(`
  <p class="screen-reader-only">{{t "charts.participants-by-stage.loader"}}</p>
  <div class="participants-by-status__loader" aria-hidden="true">
    <span class="participants-by-status__loader--chart placeholder-doughnut"></span>
    <span class="participants-by-status__loader--legend placeholder-box"></span>
    <span class="participants-by-status__loader--legend placeholder-box"></span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
