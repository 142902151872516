import { template as template_09d29149c12f4681849dad293c3b9c6d } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import { t } from 'ember-intl';
export default template_09d29149c12f4681849dad293c3b9c6d(`
  <PixBlock class="no-campaign-panel">
    <img src="{{this.rootURL}}/images/empty-state.svg" alt="" role="none" />

    <p class="no-campaign-panel__information-text hide-on-mobile">
      {{t "pages.campaigns-list.no-campaign"}}
    </p>
  </PixBlock>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
