import { template as template_bf2f41fa52b747a48c708225c86fe9df } from "@ember/template-compiler";
import onClickOutside from 'ember-click-outside/modifiers/on-click-outside';
export default template_bf2f41fa52b747a48c708225c86fe9df(`
  {{#if @display}}
    <ul class="dropdown__content" ...attributes {{onClickOutside @close}}>
      {{yield}}
    </ul>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
