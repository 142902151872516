import { template as template_e4f3fe60e0cc41fcad3b93bf858a7a51 } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { t } from 'ember-intl';
export default template_e4f3fe60e0cc41fcad3b93bf858a7a51(`
  <PixBlock class="chart-card" ...attributes>
    <h3 class="chart-card__title">
      {{@title}}
      {{#if @info}}<PixTooltip @isWide={{true}} @position="left">
          <:triggerElement>
            <PixIcon @name="help" class="chart-card__tooltip-icon" @plainIcon={{true}} @ariaHidden={{true}} />
          </:triggerElement>
          <:tooltip>
            {{t "cards.badges-acquisitions.information"}}
          </:tooltip>
        </PixTooltip>{{/if}}
    </h3>
    {{yield}}
  </PixBlock>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
