import { template as template_a71c66aa5fec469aa40f38767c940e45 } from "@ember/template-compiler";
import PixTable from '@1024pix/pix-ui/components/pix-table';
import PixTableColumn from '@1024pix/pix-ui/components/pix-table-column';
import { t } from 'ember-intl';
export default template_a71c66aa5fec469aa40f38767c940e45(`
  <section>
    <PixTable
      @variant="orga"
      @caption={{t "pages.profiles-individual-results.table.title"}}
      @data={{@competences}}
      class="table"
      @onRowClick={{@onClickCampaign}}
    >
      <:columns as |competence context|>
        <PixTableColumn @context={{context}}>
          <:header>
            {{t "pages.profiles-individual-results.table.column.skill"}}
          </:header>
          <:cell>
            <span class="competences-col__border competences-col__border--{{competence.areaColor}}">
              {{competence.name}}
            </span>
          </:cell>
        </PixTableColumn>

        <PixTableColumn @context={{context}}>
          <:header>
            {{t "pages.profiles-individual-results.table.column.level"}}
          </:header>
          <:cell>
            {{competence.estimatedLevel}}
          </:cell>
        </PixTableColumn>

        <PixTableColumn @context={{context}}>
          <:header>
            {{t "pages.profiles-individual-results.table.column.pix-score"}}
          </:header>
          <:cell>
            {{competence.pixScore}}
          </:cell>
        </PixTableColumn>
      </:columns>
    </PixTable>

    {{#unless @isShared}}
      <p class="table__empty content-text">
        {{t "pages.profiles-individual-results.table.empty"}}
      </p>
    {{/unless}}
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
