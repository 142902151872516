import { template as template_a0b933df6af54d888d856323d893f465 } from "@ember/template-compiler";
export default template_a0b933df6af54d888d856323d893f465(`
  <div class="form-field">
    <div class="form-field__element">
      {{yield}}
    </div>

    <div class="form-field__information">
      {{yield to="information"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
