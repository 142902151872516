import { template as template_449919823f374f98855563651dad120b } from "@ember/template-compiler";
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
export default template_449919823f374f98855563651dad120b(`
  {{#if (eq @isCertifiable null)}}
    {{t "pages.sco-organization-participants.table.column.is-certifiable.not-available"}}
  {{else if @isCertifiable}}
    <PixTag @color="green-light">
      {{t "pages.sco-organization-participants.table.column.is-certifiable.eligible"}}
    </PixTag>
  {{else}}
    <PixTag @color="yellow-light organization-participant__tag">
      {{t "pages.sco-organization-participants.table.column.is-certifiable.non-eligible"}}
    </PixTag>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
