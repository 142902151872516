import { template as template_c54a9eec71fc46a68d70d981e2a6692b } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_c54a9eec71fc46a68d70d981e2a6692b(`
  <PixIndicatorCard
    @title={{if
      @shouldDisplayAssessmentLabels
      (t "cards.submitted-count.title")
      (t "cards.submitted-count.title-profiles")
    }}
    @iconName="inboxIn"
    @color="green"
    @info={{t "cards.submitted-count.information"}}
    @loadingMessage={{t "cards.submitted-count.loader"}}
    @isLoading={{@isLoading}}
    ...attributes
  >
    <:default>{{@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
