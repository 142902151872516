import { template as template_566f3bde03d54f21836f465aba72a8f5 } from "@ember/template-compiler";
import PixIconButton from '@1024pix/pix-ui/components/pix-icon-button';
export default template_566f3bde03d54f21836f465aba72a8f5(`
  <PixIconButton
    aria-label={{@ariaLabel}}
    @iconName="{{if @isOpen 'chevronTop' 'chevronBottom'}}"
    aria-expanded="{{@isOpen}}"
    @triggerAction={{@onClick}}
    @withBackground={{false}}
    @size="small"
    @color="dark-grey"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
