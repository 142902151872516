import { template as template_7873ccbe19724386a3182ffba5b5df9e } from "@ember/template-compiler";
import { concat } from '@ember/helper';
export default template_7873ccbe19724386a3182ffba5b5df9e(`
  <th
    class="{{if @size (concat 'table__column--' @size)}}{{if @align (concat ' table__column--' @align)}}"
    ...attributes
  >
    {{yield}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
