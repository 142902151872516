import { template as template_6c8813e7b29a4cbb8ce738ff99219b07 } from "@ember/template-compiler";
import dayjsFormat from 'ember-dayjs/helpers/dayjs-format';
import { t } from 'ember-intl';
export default template_6c8813e7b29a4cbb8ce738ff99219b07(`
  {{#if @date}}
    {{dayjsFormat @date "DD/MM/YYYY"}}
  {{else}}
    <span aria-hidden="true">-</span>
    <span class="screen-reader-only">{{t "components.date.no-date"}}</span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
