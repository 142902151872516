import { template as template_f0d7bc3c36fe417f97cd76184565bfa7 } from "@ember/template-compiler";
export default template_f0d7bc3c36fe417f97cd76184565bfa7(`
  <div class="action-bar">
    <p class="action-bar__informations">
      {{yield to="information"}}
    </p>
    <div class="action-bar__actions">
      {{yield to="actions"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
